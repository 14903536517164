import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`
const Post = styled.div`
`
const Article = styled.article`
  min-height: 250px;
  height: 100%;
  background-color: whitesmoke;
  padding: 3rem 1rem;
  text-align: center;
`
const MainBox = styled.div`
`
const MainTitle = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap');
  font-family: 'Sawarabi Mincho', sans-serif;
  text-decoration: none;
`
const SubBox = styled.div`
`
const Dot = styled.span`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
`
const Date = styled.span`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  font-size: 12px;
  margin-top: 8px;
  display: block;
`

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Container>
        {posts &&
          posts.map(({ node: post }) => (
            <Post className="is-parent column is-6" key={post.id}>
              <Article className="tile is-child notification">
                <MainBox>
                  <MainTitle>
                    <Link
                      className="title"
                      style={{
                        fontSize: `14px`,
                        textDecoration: `none`
                      }}
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </MainTitle>
                  <Date>{post.frontmatter.date}</Date>
                </MainBox>
                <SubBox>
                  {/* {post.excerpt} */}
                  <br />
                  <Link
                    className="button"
                    to={post.fields.slug}
                    style={{
                      fontSize: `12px`,
                      borderRadius: `1.25rem`,
                      padding: `1rem`
                    }}
                  >
                    記事を読む
                  </Link>
                </SubBox>
              </Article>
            </Post>
          ))}
      </Container>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
