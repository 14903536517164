import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Timeline } from 'react-twitter-widgets'
import ReactPlayer from 'react-player'
import InstagramEmbed from 'react-instagram-embed'
import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
`
const MainImage = styled.div`
  width: 100vw;
  height: 70vh;
  max-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 32vh;
    max-width: 100%;
    left: 0;
    right: 0;
    top: 0;
  }
`
const MainTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
  }
`
const MainTitle = styled.h1`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  color: #e2f1f8;
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 20px;
  @media screen and (max-width: 543px) {
    font-size: 25px;
}
`

const MainDescription = styled.h3`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  color: #e2f1f8;
  font-size: 24px;
  padding-bottom: 20px;
  @media screen and (max-width: 543px) {
    font-size: 16px;
}
`
const Section = styled.section`
  padding: 3rem 1.5rem;
`
const SubContainer = styled.div`
  margin: 0 auto;
`
const Column = styled.div`
`
const BlogBox = styled.div`
  width: 100%;
`
const TwitterBox = styled.div`
`
const TwitterInlineBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: 768px) {
  }
`
const YoutubeBox = styled.div`
  width: 100%;
  margin-top: 30px;
`
const YoutubeInlineBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px auto 30px auto;
  @media screen and (max-width:1199px) {
    flex-direction: column;
    align-items: center;
  }
`
const Youtube = styled.div`
  margin-right: 20px;
  @media screen and (max-width:1199px) {
    margin-right: 0;
    margin-top: 20px;
  }
`
const InstagramBox = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
  }
`
const InstagramInlineBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 30px auto;
  @media screen and (max-width: 768px) {
  }
`
const NoteBox = styled.div`
  width: 100%;
  margin-top: 30px;
`
const NoteInlineBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px auto 30px auto;
  @media screen and (max-width: 768px) {
  }
`
const Title = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  font-size: 24px;
  text-align: center;
  padding-bottom: 20px;
`
const Description = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap');
  font-family: 'Libre Baskerville', serif;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  @media screen and (max-width: 768px) {
    text-align: left;
  }
`
const BlogLinkBox = styled.div`
`

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
}) => (
  <Container>
    <MainImage
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`
      }}
    >
      <MainTitleBox>
        <MainTitle>{title}</MainTitle>
        <MainDescription>{subheading}</MainDescription>
      </MainTitleBox>
    </MainImage>
    <Section>
      <SubContainer className="container">
        <Column className="column is-10 is-offset-1">
          <TwitterBox>
            <Title>Twitter</Title>
            <Description>
              毎日下着やイベント情報について話しています。おはよインナーもこちらから。
            </Description>
            <TwitterInlineBox>
              <Timeline
              dataSource={{
                sourceType: 'Timeline',
                screenName: 'lingerie1108'
              }}
              options={{
                username: 'lingerie1108',
                width: '100%',
                height: '500'
              }}
              onLoad={() => console.log('')}
              />
            </TwitterInlineBox>
          </TwitterBox>
          <YoutubeBox>
            <Title>Youtube</Title>
            <Description>
              下着の知識を動画でわかりやすくしています！
            </Description>
            <YoutubeInlineBox>
            <Youtube>
              <iframe width="280" height="150" src="https://www.youtube.com/embed/jr-Yn7WdyPM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Youtube>
            <Youtube>
              <iframe width="280" height="150" src="https://www.youtube.com/embed/Var7fZqMaKM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Youtube>
            <iframe width="280" height="150" src="https://www.youtube.com/embed/pTiMFDm9_2M" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </YoutubeInlineBox>
          </YoutubeBox>
          {/* <InstagramBox>
            <Title>Instagram</Title>
            <Description>
              毎週金曜日22:00~インスタライブ配信。<br />
              普段はちーちょろすの日常をストーリー配信したり、下着のまとめを投稿したりしています。
            </Description>
            <InstagramInlineBox>
            <InstagramEmbed
              url='https://www.instagram.com/p/B68gxlIFiNk/'
              maxWidth={320}
              hideCaption={true}
              containerTagName='div'
              protocol=''
              injectScript
              onLoading={() => {}}
              onSuccess={() => {}}
              onAfterRender={() => {}}
              onFailure={() => {}}
            />
            </InstagramInlineBox>
          </InstagramBox> */}
          <NoteBox>
            <Title>note</Title>
            <Description>
              下着の知識のまとめを配信中。今更聞けない下着の知識もたくさんです！
            </Description>
            <NoteInlineBox>
              <iframe
                className="note-embed"
                src="https://note.com/embed/notes/n762782f61702"
                height="400"
                style={{
                  border: `0`,
                  display: `block`,
                  maxWidth: `99%`,
                  height: `480px`,
                  width: `494px`,
                  padding: `0`,
                  margin: `10px 0`,
                  position: `static`,
                  visibility: `visible`
                }}
              ></iframe>
            </NoteInlineBox>
          </NoteBox>
          <BlogBox>
            <Title>BLOG</Title>
            <BlogRoll />
            <BlogLinkBox className="column is-12 has-text-centered">
              <Link
                className="btn"
                to="/blog"
                style={{
                  width: `200px`,
                  maxWidth: `200px`,
                  padding: `18px auto 18px`,
                  textAlign: `center`,
                  fontSize: `14px`,
                  color: `black`,
                  fontWeight: `400`,
                  border: `1px solid #ccc`,
                  padding: `1.25reb 1rem`,
                  borderRadius: `1.5rem`,
                  boxShadow: `none`
                }}
              >
                もっとみる
              </Link>
            </BlogLinkBox>
          </BlogBox>
        </Column>
      </SubContainer>
    </Section>
  </Container>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`
